import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "../../homepageComponents/blog/index.module.css"
import PropTypes from "prop-types"
import LinkedIn from "../../socialIcons/linkedin"
import Email from "../../socialIcons/email"

const Person = ({ name, description, title, LinkedInUrl, email, img }) => {
  const data = useStaticQuery(graphql`
    query jkcn {
      file(relativePath: { eq: "Manchester-750x400.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      className={"bg-light flex lg:flex-row flex-col rounded row-span-1 m-5 relative"}
    >
      <div
        className="absolute"
        style={{ top: "-6rem" }}
        id={name.toLowerCase().split(" ").join("-")}
      >{" "}</div>
      <div>
        <Img
          className={styles.blogImg}
          // style={{
          //   height: "150px",
          //   width: "150px",
          //   borderRadius: "0.25rem 0 0rem 0.25rem",
          // }}
          fluid={
            img ? img.childImageSharp.fluid : data.file.childImageSharp.fluid
          }
        />
      </div>
      <div className={"p-5 w-full"}>
        <div className={"flex justify-between"}>
          <div>
            <div className={"text-primary mb-1 text-lg font-semibold"}>
              {name}
            </div>
            <div className={"font-normal mb-1 text-primaryLight"}>{title}</div>
          </div>
          <div>
            <LinkedIn url={LinkedInUrl} />
            <Email email={email} />
          </div>
        </div>
        <div className={"text-primary text-sm mt-2  "}>{description}</div>
      </div>
    </div>
  )
}

Person.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  LinkedInUrl: PropTypes.string,
  email: PropTypes.string,
}

export default Person
