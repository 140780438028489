import React from "react"

import Layout from "../components/layout/index"
import SEO from "../components/seo"

import Main from "../components/teamComponents"
import People from "../components/teamComponents/people"

const ClientPage = props => {
  const [showNav, setShowNav] = React.useState(false)

  const [currency, setCurrency] = React.useState("uk")

  React.useEffect(() => {
    setCurrency(props.pathContext.currency || "uk")
  }, [])

  const onScroll = e => {
    if (e.target.scrollTop > 100) {
      setShowNav(true)
    } else {
      setShowNav(false)
    }
  }

  return (
    <>
      <SEO
        title="About Us"
        description={"About Referment, our history and our team"}
      />
      <Layout
        isBase={!props.pathContext.currency}
        showNav={showNav}
        page={"about"}
        currency={currency}
      >
        <div onScroll={e => onScroll(e)} className={""}>
          <Main />
          <People />
        </div>
      </Layout>
    </>
  )
}

export default ClientPage
