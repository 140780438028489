import React from "react"
// import { Blog } from "../../homepageComponents/blog"
import Person from "../person"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const img = useStaticQuery(graphql`
    query peoplepictures {
      alex: file(relativePath: { eq: "people/Alex Odwell Referment.webp" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eanna: file(
        relativePath: { eq: "people/Eanna for Website.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      george: file(relativePath: { eq: "people/George Rose Referment.webp" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aelin: file(relativePath: { eq: "people/Aelin Raines Referment.webp" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      max: file(relativePath: { eq: "people/Max Murmann Referment.webp" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ameen: file(relativePath: { eq: "people/Ameen Azeez Profile.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jon: file(relativePath: { eq: "people/Jon Kay Referment.webp" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tod: file(relativePath: { eq: "people/Tod Profile.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      josh: file(relativePath: { eq: "people/Josh Woodman Referment.webp" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      james: file(relativePath: { eq: "people/James Maguire Referment.webp" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bryan: file(relativePath: { eq: "people/Bryan Referment.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      phoebe: file(relativePath: { eq: "people/Phoebe Referment.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <div className={"flex flex-col container mx-auto"}>
        <div className={""}>
          <h3 className={"heading mb-4"}>
            Our team<span className={"red"}>.</span>{" "}
          </h3>
        </div>
        <div>
          <div
            className={"grid md:grid-cols-2"}
            style={{ gridAutoRows: "1fr" }}
          >
            <Person
              name={"Alex Odwell"}
              title={"Founder/MD"}
              description={
                "With over 10 years experience in recruitment working with hundreds of companies and thousands of candidates Alex is passionate about adding value to everyone who comes into contact with Referment."
              }
              LinkedInUrl={"http://linkedin.com/in/alexodwellreferment/"}
              email={"alex@referment.com"}
              img={img.alex}
            />
            <Person
              name={"Jon Kay"}
              title={"Head of Sales"}
              description={
                "With over 8 years of experience in recruiting across the whole of FinTech, Jon is passionate about improving efficiency throughout the whole recruitment lifecycle for both candidates and clients. Shaking the recruitment stigma and adding positive experiences to all our Referment users, candidates and clients is his MO."
              }
              LinkedInUrl={"https://www.linkedin.com/in/jonkayreferment/"}
              email={"jon@referment.com"}
              img={img.jon}
            />
            <Person
              name={"Tod Bamber"}
              title={"Head of APAC"}
              description={
                "Tod’s been working for Referment for two years, starting in London before moving to Sydney to launch Referment Australia. An avid sportsperson/enthusiast, he plays semi-professional football and has spent the last 8 years building his own businesses out in Sydney. Speak to him about all your Australian hiring needs!"
              }
              LinkedInUrl={"https://www.linkedin.com/in/tod-bamber-78206315a/"}
              email={"tod@referment.com"}
              img={img.tod}
            />
            <Person
              name={"Eanna Flanagan"}
              title={"Senior Relationship Manager"}
              description={
                "Eanna recently joined Referment with 5 years experience in FinTech technology recruitment. He's a rugby fan with RFU coaching qualifications so we guess you could call him our Scrum Master...."
              }
              LinkedInUrl={
                "https://www.linkedin.com/in/eanna-flanagan-41aa6b10b/"
              }
              email={"eanna@referment.com"}
              img={img.eanna}
            />
            <Person
              name={"George Rose"}
              title={"Relationship Manager"}
              description={
                "George joined Referment in mid-2021 joining our recruitment team here in Vauxhall. He has over 3 years of technology recruitment experience and previously played soccer at university in America."
              }
              LinkedInUrl={"https://www.linkedin.com/in/george-rose-996730ab/"}
              email={"george@referment.com"}
              img={img.george}
            />
            <Person
              name={"Josh Woodman"}
              title={"Head of Marketing"}
              description={
                "With over 5 years of marketing experience in SAAS and recruitment, Josh is passionate about promoting our clients' jobs across the Referment network via the platform and LinkedIn. He's the reason you're seeing our faces in videos across the web."
              }
              LinkedInUrl={"https://www.linkedin.com/in/joshua-woodman/"}
              email={"josh@referment.com"}
              img={img.josh}
            />
            <Person
              name={"Phoebe Linkleter"}
              title={"Marketing & Operations"}
              description={
                "Phoebe joined Referment in September 2021 after studying English at Nottingham Trent University. She manages our social media accounts and supports our relationship managers with administrative help. Phoebe also organises all our social events too!"
              }
              LinkedInUrl={"https://www.linkedin.com/in/phoebe-linkleter-727351181/"}
              email={"phoebe@referment.com"}
              img={img.phoebe}
            />
            <Person
              name={"James Maguire"}
              title={"Head of Data Science"}
              description={
                "James joined Referment in early 2021 to strengthen our data team. He studied Computational Linguistics at post-graduate level at Georgetown University and has previous recruitment industry experience after working at Adecco."
              }
              LinkedInUrl={
                "https://www.linkedin.com/in/james-maguire-009807b4/"
              }
              email={"james@referment.com"}
              img={img.james}
            />
            <Person
              name={"Max Murmann"}
              title={"Data Scientist"}
              description={
                "Max joined Referment in 2020 after working for an AI start-up in London. He’s an avid cyclist and rock climber when he’s not building graphs, perfecting our matching algorithm or taking analogue photos of reflections in puddles."
              }
              LinkedInUrl={"https://www.linkedin.com/in/max-murmann/"}
              email={"max@referment.com"}
              img={img.max}
            />
            <Person
              name={"Bryan Leung"}
              title={"Data Scientist"}
              description={
                "Bryan joined us in mid 2021 after two years at Adecco. He graduated from Warwick University with a degree in Mathematics and works alongside Max and James in our Data Science team!"
              }
              LinkedInUrl={"https://www.linkedin.com/in/b-leung/"}
              email={"bryan@referment.com"}
              img={img.bryan}
            />
            <Person
              name={"Ameen Azeez"}
              title={"Web Developer"}
              description={
                "Ameen started working for Referment in 2020 after previously working for an exciting AI start-up. He's constantly studying and learning new development tools and is the rock that keeps the Referment platform running smoothly!"
              }
              LinkedInUrl={"https://www.linkedin.com/in/ameen-azeez/"}
              email={"ameen@referment.com"}
              img={img.ameen}
            />
          </div>
        </div>
      </div>
    </>
  )
}
