import React from "react"

export default () => {
  return (
    <>
      <div
        className={
          "container mx-auto min-h-screen pt-40 lg:pt-0 tracking-wide pb-20 lg:pb-4 md:pt-0 p-8 flex justify-center flex-col"
        }
      >
        <div className={"text-4xl mb-8 font-black"}>
          About Referment<span className={"red"}>.</span>
        </div>
        <div className={"text-xl"}>
          Referment was founded by Alex Odwell in 2016 with the goal of building
          the most efficient and effective FinTech recruitment solution. We’re
          combining the latest data science and machine learning techniques with
          our 30+ years of combined recruitment experience in order to provide a
          better recruitment experience for all.
          <br />
          <br />
          Our growing network of over 30,000 industry professionals
          crowd-source untapped talent in the form of referrals. Our community
          members refer people they know to career-defining roles on the
          Referment platform and earn referral rewards when their referral
          interviews.
        </div>
      </div>
    </>
  )
}
